import React from 'react';
import { PathService } from '../../services';
import { OurInsightsBlogViewContext, TemplateProps } from '../../types';
import { OurInsightsBlogView } from '../../views';

const OurInsightsBlog: React.FunctionComponent<
  TemplateProps<OurInsightsBlogViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurInsightsBlogView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
      relatedPosts={JSON.parse(pageContext.relatedPosts)}
    />
  );
};

export default OurInsightsBlog;
